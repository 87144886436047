import React from "react";

import { formatPriceWithSeparato } from "../../utils/index";

import {
  Container,
  TableHead,
  Table,
  TableBody,
  TableRemoveButton,
} from "./styles";

function TableCart({ head, bodyData, onClickRemove }) {
  return (
    <Container>
      <Table>
        <TableHead>
          <tr>
            {head.map((hd, index) => (
              <th key={index}>{hd}</th>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {bodyData.map((data, index) => (
            <tr key={index}>
              <td>{data.quantity}</td>
              <td>
                <h1>{data.name}</h1>
                <div className="descripiton">
                  <h4>{data.observation}</h4>
                </div>
              </td>
              <td>R$ {formatPriceWithSeparato(data.priceProductTotal)}</td>
              <td>
                <TableRemoveButton onClick={onClickRemove} name={`${index}`} />
              </td>
            </tr>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default TableCart;
