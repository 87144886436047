import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import formatPrice from '../../utils';
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemImg,
  ListItemShortDescription,
  ListTitle,
  PriceTag,
} from './HighlitghtsListStyles';
import defaultImg from '../../assets/img/productDefaultImg.png';
import TranslatorFunction from '../I18n/Translator';

const highlightsMapFunction = (highlight, index, domain, servicemode, language) => {
  const { _id, name, imageURL, value, shortDescription, description} = highlight;

  let fileURL = null;
  let short = !!description[0]?  (description[0].shortDescription[0][language] !== undefined ? description[0].shortDescription[0][language]  : shortDescription ): shortDescription;
  if (Array.isArray(imageURL) && imageURL.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    fileURL = imageURL[0];
  }

  if (!Array.isArray(imageURL)) {
    fileURL = imageURL;
  }
  if (Array.isArray(imageURL) && imageURL.length === 0) {
    fileURL = [defaultImg];
  }

  return (
    <Link
      to={{
        pathname: `/${domain}/${servicemode}/Destaques/Destaque/${name}`,
        state: { product: highlight },
      }}
      key={_id}
    >
      <ListItem index={index}>
        <ListItemImg img={fileURL}>
          {value !== 0 ? (
            <PriceTag hasImg={fileURL}>{formatPrice(value)}</PriceTag>
          ) : (
            ''
          )}
        </ListItemImg>
        <ListItemTitle>{name}</ListItemTitle>
        <ListItemShortDescription>{short}</ListItemShortDescription>
      </ListItem>
    </Link>
  );
};

const HighlightsList = ({ highlights }) => {
  const { domain, servicemode } = useParams();

  const browse = TranslatorFunction({ path: 'browse.message' })
  const theHighlights = TranslatorFunction({ path: 'theHighlights.message' })
  const language = TranslatorFunction({ path: 'language.message' });
  return highlights.length > 0 ? (
    <List>
      <ListTitle>
        <div>
          <h2>
            {browse}
          </h2>
          <h2>&nbsp;
            {theHighlights}
          </h2>
        </div>
      </ListTitle>
      {highlights.map((highlight, index) =>
        highlightsMapFunction(highlight, index, domain, servicemode, language)
      )}
    </List>
  ) : null;
};

HighlightsList.defaultProps = {
  highlights: [],
};

HighlightsList.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default HighlightsList;
