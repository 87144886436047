import styled from "styled-components";

export const Container = styled.div``;

export const ContainerTitles = styled.div`
  margin-top: 15px;
  text-align: center;
  h1 {
    color: #858585;
    font-size: 28px;
    line-height: 40px;
    font-weight: lighter;
  }
  h3,
  b {
    color: #ff5533;
    font-size: 15px;
    font-weight: lighter;
  }
`;

export const Form = styled.div``;

export const InputContainer = styled.div`
  width: 90%;
  margin: 20px auto;
`;

export const InputGroupContainer = styled.div`
  width: 90%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 24px;
  div:nth-child(2) {
    width: 93% !important;
  }
`;

export const ContainerTable = styled.div`
  height: 2px;
  background-color: #ffdcd5;
  margin-top: 42px !important;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 10px auto;
  h3 {
    color: #565656;
    font-size: 11px;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 12px;
  }
`;
