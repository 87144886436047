const defaultTheme = {
  name: "defaultTheme",
  colors: {
    mainColor: "#FF5533",
    mainColorDark: "#401E0D",
    mainColorLight: "#FFD5CC",
    mainColorContrast: "#FFFFFF",
    mainColorDisabled: "#FFF4F2",
    textColor: "#1A1A1A",
    textColorLight: "#999999",
    textColorDisabled: "#E6E6E6",
    textColorContrast: "#FFFFFF",
    statusColorDanger: "#FF0000",
    statusColorAlert: "#FF9500",
    statusColorAttention: "#FFD500",
    statusColorSuccess: "#00D991",
    statusColorNeutral: "#00BFFF",
  },
};

export default defaultTheme;
