import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputWrapper, Input, Label } from "./InputTextStyles";

const genRandomIdHash = () => Math.random().toString(36).substr(2, 5);

const InputText = (props) => {
  const { label, value, disabled, onChange, id, required, name, onBlur } =
    props;
  const [disableInput] = useState(disabled);

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  const handleOnBlur = (event) => {
    onBlur(event.target.value);
  };

  return (
    <InputWrapper>
      <Input
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        id={id}
        disabled={disableInput}
        pattern=".+"
        required={required}
        name={name ?? ""}
      />
      <Label htmlFor={id}>{label}</Label>
    </InputWrapper>
  );
};

InputText.defaultProps = {
  disabled: false,
  label: "",
  id: "" || genRandomIdHash(),
  onChange: () => {},
  onBlur: () => {},
  value: "",
  required: false,
};

InputText.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
};

export default InputText;
