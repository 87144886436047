import { useLocation } from 'react-router-dom';

const useDomain = () => {
  const location = useLocation();
  const domain = location.pathname.split('/').filter((str) => str !== '')[0];

  return domain;
};

export default useDomain;
