import React from "react";
import { isBrowser } from "react-device-detect";
import {
  Background,
  ContentContainer,
  Title,
  Paragraph,
  StatusNeutralColorBar,
  I18NDIV,
} from './WebDisclaimerStyles';
import myTripLogoT from "../../assets/img/logo_vertical.svg";
import TranslatorFunction from "../I18n/Translator"
import I18n from '../I18n';

const WebDisclaimer = ({ children }) => {
  const showDisclaimer = isBrowser;

  const helloHowAreYou = TranslatorFunction({ path: 'helloHowAreYou.message' });
  const forNowOurMenuCanOnlyBeAccessedOnMobilePhones = TranslatorFunction({ path: 'forNowOurMenuCanOnlyBeAccessedOnMobilePhones.message' });
  const weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice = TranslatorFunction({ path: 'weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice.message' });

  if (showDisclaimer) {
    return (
      <Background>
        <I18NDIV>
          <I18n />
        </I18NDIV>
        <ContentContainer>
          <img src={myTripLogoT} alt="Logo My Trip Menu" />

          <Title>
            {helloHowAreYou}
            <br />
            <br />
            {forNowOurMenuCanOnlyBeAccessedOnMobilePhones}
          </Title>
          <StatusNeutralColorBar />
          <Paragraph>
            {weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice}
          </Paragraph>
        </ContentContainer>
      </Background>
    );
  }

  return children;
};

export default WebDisclaimer;
