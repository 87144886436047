/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory, useParams } from "react-router";

import {
  Container,
  TitleWrapper,
  SubtitleWrapper,
  ButtonsWrapper,
} from "./styles";

import waiterSVG from "../../../assets/img/waiter.svg";
import Button from "../../../components/button/Button";
import Link from "../../../components/link/Link";
import TranslatorFunction from "../../../components/I18n/Translator";

function HomeLocal() {
  const history = useHistory();
  const { domain } = useParams();

  const goodThatYouAreHere = TranslatorFunction({ path: 'goodThatYouAreHere.message' });
  const accessMenu = TranslatorFunction({ path: 'accessMenu.message' });
  const chooseTheItemYouWantAnd = TranslatorFunction({ path: 'chooseTheItemYouWantAnd.message' });
  const toPlaceYourOrder = TranslatorFunction({ path: 'toPlaceYourOrder.message' });
  const callAnAttendant = TranslatorFunction({ path: 'callAnAttendant.message' });
  const back = TranslatorFunction({ path: 'back.message' });

  return (
    <Container>
      <>
        <TitleWrapper>
          <h1>
            {goodThatYouAreHere}
            !</h1>
        </TitleWrapper>
        <img src={waiterSVG} alt="" srcset="" />
        <SubtitleWrapper>
          <h2>
            {chooseTheItemYouWantAnd}
            <b>
              {callAnAttendant}
            </b>
            {toPlaceYourOrder}
          </h2>
        </SubtitleWrapper>
        <ButtonsWrapper>
          <Button onClick={() => history.push(`/${domain}/local/menu`)}>
            <h3>
              {accessMenu}
            </h3>
          </Button>
        </ButtonsWrapper>
        <Link to={`/${domain}`}>
          {back}
        </Link>
      </>
    </Container>
  );
}

export default HomeLocal;
