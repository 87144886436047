import styled, { css } from "styled-components";

const cssPrimary = css`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorLight : theme.colors.textColorContrast};
`;

const cssSecondary = css`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.mainColorContrast : theme.colors.mainColorContrast};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
`;

const cssTertiary = css`
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  border: none;
`;

const ButtonStyled = styled.button`
  border-radius: 16px;
  outline: none;
  width: 100%;
  height: 54px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  ${({ type }) =>
    (type === "primary" && cssPrimary) ||
    (type === "secondary" && cssSecondary) ||
    (type === "tertiary" && cssTertiary)};
`;

export default ButtonStyled;
