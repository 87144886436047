export default {
  translations: {
    language: {
      message: "de",
    },
    welcomeToOurDigitalMenu: {
      message: "Willkommen in unserer digitalen Speisekarte",
    },
    beWelcome: {
      message: "willkommen",
    },
    hello: {
      message: "Hallo",
    },
    comments: {
      message: "Kommentare",
    },
    selectUntil: {
      message: "auswählen bis",
    },
    optionsYouSelected: {
      message: " Optionen. Sie haben ausgewählt",
    },
    youMustAddAllRequiredComplements: {
      message: "Sie müssen alle erforderlichen Add-Ons hinzufügen.",
    },
    myOrder: {
      message: "MEINE ANFRAGE",
    },
    emptyCart: {
      message: "LEERER WARENKORB",
    },
    cartQuantity: {
      message: 'Menge',
    },
    cartItem: {
      message: 'Artikel',
    },
    cartValue: {
      message: 'Wert',
    },
    addMoreItems: {
      message: "WEITERE ARTIKEL HINZUFÜGEN",
    },
    subtotal: {
      message: "ZWISCHENSUMME",
    },
    total: {
      message: "TOGESAMTTAL",
    },
    formOfPayment: {
      message: "ZAHLUNGSART",
    },
    deliveryFee: {
      message: "Liefergebühr",
    },
    deliveryIn: {
      message: "Lieferung ein",
    },
    sendOrder: {
      message: "ANFRAGE SENDEN",
    },
    ourResponseTimeIsUpTo: {
      message: "Unsere Reaktionszeit beträgt bis zu",
    },
    minutes: {
      message: "Protokoll",
    },
    back: {
      message: "KOMM ZURÜCK",
    },
    backToMenu: {
      message: "ZURÜCK ZUM MENÜ",
    },
    confirmAddress: {
      message: "ADRESSE BESTÄTIGEN",
    },
    confirmTakAway: {
      message: "RÜCKTRITT BESTÄTIGEN",
    },
    invalidCoupon: {
      message: "Ungültiger Gutschein",
    },
    discountApplied: {
      message: "Rabatt angewendet.",
    },
    discount: {
      message: 'RABATT'
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: "Sie müssen um Wechselgeld für einen Betrag bitten, der größer als ist",
    },
    checkAllFields: {
      message: "Überprüfen Sie alle Felder.",
    },
    pleaseSelectAProductBeforeProceeding: {
      message: "Bitte wählen Sie ein Produkt aus, bevor Sie fortfahren",
    },
    selectAPaymentMethod: {
      message: "Wählen Sie eine Zahlungsmethode aus",
    },
    chooseOneOfTheOptions: {
      message: "Wählen Sie eine der Optionen",
    },
    catalog: {
      message: "Katalog",
    },
    deliveryTakeAway: {
      message: "Lieferung | Rückzug",
    },
    localConsumption: {
      message: "Lokaler Verbrauch",
    },
    simpleEasyFastDigital: {
      message: "Einfach. Einfach. Schnell. Digital.",
    },
    imagesAreForIllustrationPurposesOnly: {
      message: "Bilder dienen nur zur Veranschaulichung.",
    },
    changeFor: {
      message: "ändern für",
    },
    productImage: {
      message: "Produktbild",
    },
    weAreNotOpenYet: {
      message: "wir haben noch nicht geöffnet",
    },
    checkOur: {
      message: "Schauen Sie sich unsere an",
    },
    openingHours: {
      message: "Öffnungszeiten",
    },
    accessMenu: {
      message: "ZUGANGSMENÜ",
    },
    oops: {
      message: "Hoppla",
    },
    browse: {
      message: "NAVIGIEREN",
    },
    theHighlights: {
      message: "NACH DEN HIGHLIGHTS",
    },
    helloHowAreYou: {
      message: "Hallo, wie geht's dir?",
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: "Auf unsere Speisekarte kann vorerst nur über Mobiltelefone zugegriffen werden.",
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: "Wir arbeiten bereits daran, Ihnen das beste Online-Menüerlebnis zu bieten, auf das Sie von jedem Gerät aus zugreifen können.",
    },
    identification: {
      message: "IDENTIFIKATION",
    },
    mandatoryFilling: {
      message: "obligatorische Füllung",
    },
    name: {
      message: "Name",
    },
    phone: {
      message: "Telefon",
    },
    cityTakeAway: {
      message: "Stadt/Entzug",
    },
    street: {
      message: "Straße",
    },
    neighborhoods: {
      message: "Nachbarschaften",
    },
    number: {
      message: "Nummer",
    },
    complement: {
      message: "Ergänzen",
    },
    reference: {
      message: "Bezug",
    },
    coupon: {
      message: "COUPON",
    },
    add: {
      message: "HINZUFÜGEN",
    },
    typeWhatYouAreLookingFor: {
      message: "Geben Sie ein, wonach Sie suchen...",
    },
    goodThatYouAreHere: {
      message: "Schön, dass du da bist",
    },
    chooseTheItemYouWantAnd: {
      message: "Wählen Sie den gewünschten Artikel und",
    },
    toPlaceYourOrder: {
      message: "um Ihre Bestellung aufzugeben",
    },
    callAnAttendant: {
      message: "Rufen Sie eine Begleitperson an",
    },
    SUN: {
      message: 'Sonn',
    },
    MON: {
      message: 'Mon',
    },
    TUES: {
      message: 'Diens',
    },
    WED: {
      message: 'Mitt',
    },
    THU: {
      message: 'Donner',
    },
    FRI: {
      message: 'Frei',
    },
    SAT: {
      message: 'Sams',
    },
    from: {
      message: "IN"
    },
    to: {
      message: "bis zu"
    },
    hum: {
      message: 'Hmmm',
    },
    nothave: {
      message: "sieht so aus, als hätten wir es noch nicht"
    },
    ismenu: {
      message: "Es steht auf unserer Speisekarte..."
    },
    Additional: {
      message: "Zusätzlich"
    },
    takeAway: {
      message: "Wegbringen"
    },
  },
};
