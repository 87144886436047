import styled from 'styled-components';

const Input = styled.input`
  border: 0;
  outline: none;
  transition: .2s ease-in-out;
  box-sizing: border-box;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  padding: 0 12px;
  line-height: 40px;
  width: 100%;
  color: ${({ theme }) => theme.colors.textColor};
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mainColor};

`;

const Label = styled.label`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  top: 10px;
  left: 0; 
  right: 0;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  cursor: text;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 12px 5px 12px;
  transition: .2s ease-in-out;
  color: ${({ theme }) => theme.colors.mainColor};
`;

const InputWrapper = styled.div`
  position: relative;

  ${Input}:valid ~ ${Label},
  ${Input}:focus ~ ${Label} {
    top: -8px;
    font-size: 12px;
    pointer-events: none;
  }

`;

export {
  InputWrapper,
  Input,
  Label,
};
