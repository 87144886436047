import React from "react";
import PropTypes from "prop-types";
import BlockUiCustomLoader from "./BlockLoaderStyles";

const BlockLoader = (props) => {
  const { blocking, children, isCart = true } = props;
  return (
    <BlockUiCustomLoader tag="div" blocking={blocking} isCart={isCart}>
      {children}
    </BlockUiCustomLoader>
  );
};

BlockLoader.defaultProps = {
  blocking: false,
  children: <></>,
};

BlockLoader.propTypes = {
  blocking: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any,
  ]),
};

export default BlockLoader;
