import styled from "styled-components";
import CloseIcon from "../../assets/img/closeIcon.svg";

export const Container = styled.div``;

export const Table = styled.table`
  width: 100%;
  margin: 0px auto 10px;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  th:nth-child(1) {
    width: 20%;
    text-align: center;
  }
  th {
    text-align: left;
    color: #ff5533;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const TableBody = styled.tbody`
  td:nth-child(1) {
    text-align: center;
  }
  td {
    font-weight: bold;
    padding: 16px 0;
    h1 {
      font-size: 14px;
    }
    h4 {
      font-size: 12px;
      color: #565656;
      font-weight: lighter;
    }
  }
  tr {
    border-bottom: 1px solid #ffdcd5;
  }
`;

export const TableRemoveButton = styled.button`
  background-image: url(${CloseIcon});
  background-color: transparent;
  border: 0px;
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
`;
