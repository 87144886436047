import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 2px 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  border: 1px solid ${({ theme }) => theme.colors.mainColor};
  border-radius: 16px;
`;

const LabelStyle = styled.label`
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export {
  Container,
  LabelStyle,
};
