import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/button/Button';
import ReactPixel from 'react-facebook-pixel';

import { PageWrapper, TitleWrapper, ButtonWrapper } from './HomeStyles';
// import { mobileModel } from "react-device-detect";
import restMenus from '../../api/menus/rest-menus';
import {
  clearSession,
  setModeOperation,
  setOperationId,
  setTakeaway,
} from '../../services/localStorage';
import { days, scrollToTop } from '../../utils';
import { MenuForm } from './MenuForm';
import { CartContext } from '../../hooks/CartContext';
import BlockLoader from '../../components/block-loader/BlockLoader';
import TranslatorFunction from '../../components/I18n/Translator';

const HomePage = () => {
  const [, setCart] = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const [operations, setOperations] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const { domain } = useParams();
  const history = useHistory();

  const welcomeToOurDigitalMenu = TranslatorFunction({ path: 'welcomeToOurDigitalMenu.message' });

  const catalog = TranslatorFunction({ path: 'catalog.message' });
  const deliveryTakeAway = TranslatorFunction({ path: 'deliveryTakeAway.message' });
  const localConsumption = TranslatorFunction({ path: 'localConsumption.message' });

  const defineOperations = [
    {
      id: '1',
      name: deliveryTakeAway,
      link: 'delivery',
      originalName: 'Delivery',
      _id: '',
    },
    {
      id: '0',
      name: localConsumption,
      link: 'local',
      originalName: 'Atendimento Presencial',
      _id: '',
    },
    {
      id: '3',
      name: catalog,
      link: 'local',
      originalName: 'Catálogo',
      _id: '',
    },
  ];
  const chooseOneOfTheOptions = TranslatorFunction({ path: 'chooseOneOfTheOptions.message' });

  const handleMenusResponse = ({ data }) => {


    data.forEach((value) => {
      if (value.operationName === 'Takeaway') {
        setTakeaway(value._id);
      }
    });

    const formattedOperations = defineOperations
      .map((e) => {
        const finded = data.find((op) => op.id === e.id);
        return { ...e, _id: finded?._id };
      })
      .filter((e) => e._id !== undefined)
      .reverse();

    setOperations(formattedOperations);
  };

  const handleSchedulesResponse = ({ data }) => {
    setSchedules(data);
    setIsLoading(false);
  };

  const fetchMenus = () =>
    restMenus
      .fetchOperations(domain.toLowerCase())
      .then(handleMenusResponse)
      .catch(() => setIsLoading(false));

  const fetchSchedules = () =>
    restMenus
      .fetchSchedules(domain.toLowerCase())
      .then(handleSchedulesResponse);

  const fetchFacebookPixel = async () => {
    try {
      const { data } = await restMenus.fetchPixelFacebook(domain);

      if (data.facebookPixel) {
        const advancedMatching = {};

        const options = {
          autoConfig: true,
          debug: false,
        };

        ReactPixel.init(`${data.facebookPixel}`, advancedMatching, options);

        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextPage = (op, state) => {
    restMenus.addAccess(domain);

    const actualDate = new Date();

    const isClosed = isClosedRest(actualDate, state);
    setModeOperation(`${op.link === 'delivery'}`);
    setOperationId(op._id);

    const url =
      op.link === 'delivery'
        ? `/${domain}/${op.link}/menu`
        : `/${domain}/${op.link}`;

    isClosed
      ? history.push(`/${domain}/${op.link}/closed`, state)
      : history.push(url);
  };

  const redirectCatalog = (catalog) => {
    setModeOperation(`${catalog.link === 'delivery'}`);
    setOperationId(catalog._id);

    const url = `/${domain}/${catalog.link}/menu`;

    history.push(url);
  };

  const isClosedRest = (actualDate, state) => {
    const day = days[actualDate.getDay()];
    const filteredDay = state[0]?.hours?.filter((hour) => hour?.day === day)[0];
    const hoursNow = actualDate.getMinutes() + actualDate.getHours() * 60;

    if (!filteredDay) return true;
    return (
      (hoursNow > filteredDay?.closed1 || hoursNow < filteredDay?.opened1) &&
      (hoursNow > filteredDay?.closed2 || hoursNow < filteredDay?.opened2)
    );
  };

  useEffect(() => {
    clearSession();
    setCart({ ...MenuForm });
    fetchMenus();
    fetchFacebookPixel();
    fetchSchedules();
    scrollToTop();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isCatalog = operations.findIndex((op) => op.name === 'Catálogo');
    if (isCatalog >= 0) {
      const catalog = operations[isCatalog];
      redirectCatalog(catalog);
    }
    // eslint-disable-next-line
  }, [operations]);

  return (
    <BlockLoader blocking={isLoading} isCart={false}>
      <PageWrapper>
        <TitleWrapper>
          <h1>
            {welcomeToOurDigitalMenu}
            !
          </h1>
        </TitleWrapper>
        <ButtonWrapper>
          <h2>
            {chooseOneOfTheOptions}
          </h2>
          {!isLoading &&
            operations
              .filter((op) => op.name !== 'Catálogo')
              .map((op) => {
                const state = schedules.filter(
                  (scd) => scd.name === op.originalName
                );
                return (
                  <Button onClick={() => handleNextPage(op, state)}>
                    <h3>{op.name}</h3>
                  </Button>
                );
              })}
        </ButtonWrapper>
      </PageWrapper>
    </BlockLoader>
  );
};

export default HomePage;
