import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 12px auto;
  * {
    color: #ff5533;
  }

  .MuiTypography-body1 {
    color: #858585 !important;
  }
`;
