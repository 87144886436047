import styled, { keyframes, css } from 'styled-components';
import defaultImg from '../../assets/img/productDefaultImg.png';

const slideAnimation = keyframes`
  0% { transform: translate(100vh, 0%) }
  100% { transform: translate(0%, 0%) }
`;

const slideCss = css`
  transform: translate(100vh, 0%);
  animation-name: ${slideAnimation};
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
`;

const List = styled.div`
  width: 100vw;
  margin-bottom: 24px;
  overflow: auto;
  white-space: nowrap;
`;

const ListItem = styled.div`
  width: 196px;
  padding: 16px;
  display: inline-block;
  justify-content: center;
  vertical-align: top;
  ${slideCss}
`;

const ListItemImg = styled.div`
  width: 164px;
  height: 246px;
  border-radius: 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  position: relative;
  background-color: ${({ theme }) => theme.colors.mainColor};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ img }) => img || defaultImg});
`;

const PriceTag = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 4px 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
  background: ${({ theme }) => theme.colors.mainColorDark};
  color: ${({ theme }) => theme.colors.textColorContrast};
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: right;
  text-transform: uppercase;
`;

const ListItemTitle = styled.h3`
  width: 100%;
  margin-top: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  word-break: break-word;
  white-space: normal;
`;

const ListItemShortDescription = styled.h3`
  margin-top: 4px;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  word-break: break-word;
  white-space: normal;
`;

const ListTitle = styled.div`
  width: 14px;
  margin-left: 16px;
  height: 323px;
  display: inline-block;
  position: relative;
  ${slideCss}

  div {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: max-content;
    transform: translate(-50%, 0%) rotate(270deg);
    display: flex;

    h2 {
      color: ${({ theme }) => theme.colors.mainColor};
      text-transform: uppercase;
      font-weight: 300;
      font-size: 12px;
      line-height: 120%;

      &:nth-child(2n) {
        font-weight: 600;
      }
    }
  }
`;

export {
  List,
  ListTitle,
  ListItem,
  ListItemImg,
  PriceTag,
  ListItemTitle,
  ListItemShortDescription,
};
