import React, { useState } from "react";

import { MenuForm } from "../pages/home/MenuForm";

export const CartContext = React.createContext();

export const CartProvider = (props) => {
  const [cart, setCart] = useState({ ...MenuForm });
  return (
    <CartContext.Provider value={[cart, setCart]}>
      {props.children}
    </CartContext.Provider>
  );
};
