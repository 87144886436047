import styled from "styled-components";
import "react-block-ui/style.css";
import BlockUi from "react-block-ui";

const BlockUiCustomLoader = styled(BlockUi)`
  .loading-indicator {
    .loading-bullet {
      color: ${({ theme }) => theme.colors.mainColor};
    }
  }
  .block-ui-message-container {
    top: ${({ isCart }) => (isCart ? "20%" : "75%")};
  }
`;

export default BlockUiCustomLoader;
