import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import Header from "../../components/header/Header";
import { ChildrenWrapper, Footer, LayoutWrapper } from "./PageWrapperStyles";
import myTripMenuLogo from "../../assets/img/myTripHorizontalLogo.svg";
import TranslatorFunction from "../../components/I18n/Translator"

const PageWrapper = ({ children }) => (
  <LayoutWrapper>
    <div>
      <Header />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </div>
    <Footer>
      <ReactSVG src={myTripMenuLogo} />
      <p>
        {TranslatorFunction({ path: 'simpleEasyFastDigital.message' })}
      </p>
      <br />
      <p>
        <b>*</b>
        {TranslatorFunction({ path: 'imagesAreForIllustrationPurposesOnly.message' })}
      </p>
    </Footer>
  </LayoutWrapper>
);

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

PageWrapper.defaultProps = {
  children: null,
};

export default PageWrapper;
