/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';

import priceFormatter from '../../../utils';
import Badge from '../../../components/badge/Badge';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { setCartStorage } from '../../../services/localStorage';

import {
  // ImageContainer,
  InfoContainer,
  PageWrapper,
  ProductTitle,
  ProductInfo,
  PriceContainer,
  PriceTag,
  CategoryName,
  TagsContainer,
  ComplementGroupContainer,
  ComplementGroupSubTitle,
  ComplementGroupTitle,
  ComplementGroupContext,
  DivisionLine,
  ComplementContainer,
  ComplementContext,
  ComplementCount,
  ComplementButtonAdd,
  ComplementButtonReduce,
  ProductContainer,
  ProductFormContainer,
  ProductFormCounting,
  ProductFormButton,
  ProductTittle,
  ProductObsInputText,
  FullScreenImage,
  ImageFullScreen,
} from './styles';
import { formatPriceWithSeparato } from '../../../utils';
import { ProductForm } from './ProductForm';
import { CartContext } from '../../../hooks/CartContext';
import ImgAdd from '../../../assets/img/add.svg';

import ImgMenos from '../../../assets/img/menos.svg';
import { scrollToTop } from '../../../utils/index';
import Carousel from '../../../components/carousel/Carousel';
import TranslatorFunction from '../../../components/I18n/Translator';
import { isArray } from 'lodash';

const ProductInfoDeliveryPage = () => {
  const [cart, setCart] = useContext(CartContext);

  const { category } = useParams();
  const [product, setProduct] = useState({});
  const [fileSelected, setFileSelected] = useState();
  const [filesURL, setFilesURL] = useState([]);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [nameError, setNameError] = useState('');
  const [values, setValues] = useState({ ...ProductForm, category });
  const [fullScreen, setFullScreen] = useState(false);
  const comments = TranslatorFunction({ path: 'comments.message' });
  const selectUntil = TranslatorFunction({ path: 'selectUntil.message' });
  const optionsYouSelected = TranslatorFunction({ path: 'optionsYouSelected.message' });
  const youMustAddAllRequiredComplements = TranslatorFunction({ path: 'youMustAddAllRequiredComplements.message' });
  const add = TranslatorFunction({ path: 'add.message' });
  const language = TranslatorFunction({ path: 'language.message' });

  const history = useHistory();



  const initEditMode = () => {
    const newValues = { ...values };

    newValues.productId = product?._id;
    newValues.name = product.name;
    newValues.priceProduct = product?.value;
    newValues.priceProductTotal = product?.value;
    newValues.quantity = 1;
    newValues.observation = '';
    newValues.complementGroup = [];

    if (product.complementGroup !== undefined) {
      product.complementGroup.forEach((cg) => {
        newValues.complementGroup.push({
          name: cg.complementGroupId.name,
          translatedComplementGroup: cg.complementGroupId.translatedComplementGroup,
          id: cg.complementGroupId._id,
          max: cg.max,
          min: cg.min,
          quantity: 0,
          complements: [],
        });
        cg.complements.forEach((comp) => {
          newValues.complementGroup[
            newValues.complementGroup.length - 1
          ].complements.push({
            name: comp.complementId.name,
            translatedComplement: comp.complementId.translatedComplement,
            id: comp.complementId._id,
            quantity: 0,
            price: comp.price ?? 0,
            description: comp.complementId.description,
          });
        });
      });
    }

    setValues(newValues);

    let fileURL = [];

    if (Array.isArray(imageURL) && imageURL.length > 0) {
      fileURL = imageURL;
    }

    if (!Array.isArray(imageURL)) {
      fileURL = [imageURL];
    }

    if (Array.isArray(imageURL) && imageURL.length === 0) {
      fileURL = [null];
    }
    setFilesURL(fileURL);
  };

  const handleOnClickSubmit = () => {
    const isValid = values.complementGroup.every((e) => e.quantity >= e.min);
    if (isValid) {
      const newLittleCar = { ...cart };
      newLittleCar.products.push(values);
      setCart(newLittleCar);
      setCartStorage(newLittleCar);
      history.goBack();
    } else {
      const error = youMustAddAllRequiredComplements;
      setNameError(error);
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };
  const handleOnClickReduce = (event) => {
    const { value, id } = event.target;
    const newValues = { ...values };
    if (newValues.quantity > 0) {
      if (value === 'product') {
        newValues.quantity -= 1;
        newValues.priceProductTotal -= newValues.priceProduct;
      } else {
        const indexCG = newValues.complementGroup.findIndex(
          (compG) => compG.id === id
        );

        const indexC = newValues.complementGroup[indexCG].complements.findIndex(
          (comp) => {
            return comp.id === value
          }
        );

        if (
          newValues.complementGroup[indexCG].complements[indexC].quantity > 0
        ) {
          newValues.complementGroup[indexCG].complements[indexC].quantity -= 1;
          newValues.complementGroup[indexCG].quantity -= 1;
          newValues.priceProductTotal -=
            newValues.complementGroup[indexCG].complements[indexC].price;
        }
      }

      setValues(newValues);
    }
  };

  const handleOnClickAdd = (event) => {
    const { id, value } = event.target;

    const newValues = { ...values };

    if (value === 'product') {
      newValues.quantity += 1;
      newValues.priceProductTotal += newValues.priceProduct;
    } else {
      const indexCG = newValues.complementGroup.findIndex(
        (compG) => compG.id === id
      );

      const indexC = newValues.complementGroup[indexCG].complements.findIndex(
        (comp) => comp.id === value
      );

      if (
        newValues.complementGroup[indexCG].quantity <
        newValues.complementGroup[indexCG].max
      ) {
        newValues.complementGroup[indexCG].complements[indexC].quantity += 1;
        newValues.complementGroup[indexCG].quantity += 1;
        newValues.priceProductTotal +=
          newValues.complementGroup[indexCG].complements[indexC].price;
      }
    }

    setValues(newValues);
  };

  const returnTranslatedCategoryFromProduct = (products) => {
    if (products.subcategories) {
      const highlightsCategory = products.subcategories.filter(
        (subCategory) => subCategory.category.name === category,
      );
      console.log('aqui', highlightsCategory)
      return highlightsCategory[0].category?.translatedName[0]?.name[0][language] === undefined? category : highlightsCategory[0].category.translatedName[0].name[0][language];
    }
    return category;
  };

  const handleOnChangeTextArea = (event) => {
    const { value } = event.target;
    const newValues = { ...values };

    newValues.observation = value;

    setValues(newValues);
  };

  const handleChangeFile = (img) => {
    setFileSelected(img);
    setFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setFullScreen(false);
    setFileSelected();
  };

  useEffect(() => {
    if (location.state && location.state.product) {
      setProduct(location.state.product);
    }
  }, [location]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    initEditMode();
  }, [product]);

  const { name, value, imageURL, longDescription, tags } = product;
  const longDescriptionTranslated = isArray(product?.description) 
  && product?.description?.length != 0 
  ? (product?.description[0]?.longDescription[0][language] !== undefined? product?.description[0]?.longDescription[0][language] :longDescription ) 
  : longDescription;

  const formattedLongDescription = React.useMemo(() => {
    const emailPattern =
      // eslint-disable-next-line
      /((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%.\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%\+.~#?&\/\/=]*))/g;
    const test = longDescriptionTranslated?.replace(
      emailPattern,
      '<a href="$1" target="_blank" rel="noopener noreferrer" class="link-a">$1</a>'
    );
    return test;
  }, [longDescriptionTranslated]);

  const subCategorySession = JSON.parse(sessionStorage.getItem('subCategory'));

  return (
    <PageWrapper>
      <div>
        <Carousel
          imgsURL={filesURL}
          selectImg={handleChangeFile}
          isNullImage={!filesURL[0]}
        />
        <InfoContainer>
          {value !== 0 ? (
            <PriceContainer>
              <PriceTag>{priceFormatter(value)}</PriceTag>
            </PriceContainer>
          ) : (
            ''
          )}
          { console.log(product)  }
          <CategoryName>{subCategorySession == null ? returnTranslatedCategoryFromProduct(product) : (Array.isArray(subCategorySession.category?.translatedName) && subCategorySession.category?.translatedName.length > 0 ? (subCategorySession.category.translatedName[0].name[0][language] !== undefined ? subCategorySession.category.translatedName[0].name[0][language] : subCategorySession.category.name): subCategorySession.category.name)}</CategoryName>
          <ProductTitle>{name}</ProductTitle>
          <ProductInfo
            dangerouslySetInnerHTML={{ __html: formattedLongDescription }}
          />
          <TagsContainer>
            {tags && tags.map((tag) => <Badge key={tag}>{tag}</Badge>)}
          </TagsContainer>
        </InfoContainer>
      </div>
      <ComplementGroupContainer>
        {values.complementGroup?.map((complementGroup, index) => (
          <ComplementGroupContext key={index}>
            <ComplementGroupTitle>
              {Array.isArray(complementGroup.translatedComplementGroup) && complementGroup.translatedComplementGroup.length > 0  ?  (complementGroup.translatedComplementGroup[0].name[0][language] !== undefined ? complementGroup.translatedComplementGroup[0].name[0][language] : complementGroup.name): complementGroup.name}
              {complementGroup.min > 0 ? '*' : ''}
            </ComplementGroupTitle>

            <ComplementGroupSubTitle>
              {selectUntil}
              <label>
                {complementGroup.max}
              </label>
              {optionsYouSelected}
              <label>
                {complementGroup.quantity}
              </label>
            </ComplementGroupSubTitle>

            <DivisionLine />

            {complementGroup.complements.map((complement, index_comp) => (
              <div key={index_comp}>
                <ComplementContainer key={index_comp}>
                  <ComplementContext>
                    <h5>{Array.isArray(complementGroup.translatedComplementGroup) && complementGroup.translatedComplementGroup.length > 0 ? (complement.translatedComplement[0].name[0][language] !== undefined? complement.translatedComplement[0].name[0][language] : complement.name) : complement.name}</h5>
                    <h6>
                      {complement.price === null || complement.price === 0
                        ? ''
                        : ` + R$ ${formatPriceWithSeparato(complement.price)}`}
                    </h6>
                    <h6>{complement.description ?? ''}</h6>
                  </ComplementContext>
                  <ComplementCount>
                    <ComplementButtonReduce
                      value={`${complement.id}`}
                      id={`${complementGroup.id}`}
                      onClick={handleOnClickReduce}
                    >
                      <img src={ImgMenos} alt="" srcSet="" />
                    </ComplementButtonReduce>
                    <input value={complement.quantity} readOnly />
                    <ComplementButtonAdd
                      value={`${complement.id}`}
                      id={`${complementGroup.id}`}
                      onClick={handleOnClickAdd}
                    >
                      <img src={ImgAdd} alt="" srcSet="" />
                    </ComplementButtonAdd>
                  </ComplementCount>
                </ComplementContainer>
                <DivisionLine />
              </div>
            ))}
          </ComplementGroupContext>
        ))}
      </ComplementGroupContainer>
      <ProductContainer>
        <ProductTittle>
          {comments}
        </ProductTittle>
        <ProductObsInputText
          value={values.observation}
          onChange={handleOnChangeTextArea}
        />
        <ProductFormContainer>
          <ProductFormCounting>
            <ComplementButtonReduce
              value="product"
              onClick={handleOnClickReduce}
            >
              <img src={ImgMenos} alt="" srcSet="" />
            </ComplementButtonReduce>

            <input value={values.quantity} readOnly />
            <ComplementButtonAdd value="product" onClick={handleOnClickAdd}>
              <img src={ImgAdd} alt="" srcSet="" />
            </ComplementButtonAdd>
          </ProductFormCounting>
          <ProductFormButton onClick={handleOnClickSubmit}>
            {add}
            <label>
              R$ {formatPriceWithSeparato(values.priceProductTotal)}
            </label>
          </ProductFormButton>
        </ProductFormContainer>
      </ProductContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="warning"
        >
          {nameError}
        </MuiAlert>
      </Snackbar>
      <FullScreenImage fullScreen={fullScreen}>
        <AiOutlineClose fill="#fff" onClick={handleCloseFullScreen} size={18} />
        <ImageFullScreen img={fileSelected} />
      </FullScreenImage>
    </PageWrapper>
  );
};

export default ProductInfoDeliveryPage;
