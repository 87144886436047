import styled from "styled-components";
import { Link } from "react-router-dom";
import defaultImg from "../../assets/img/productDefaultImg.png";

const PageWrapper = styled.div`
  width: 100%;
  flex: 1;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  height: ${({ img }) => (img ? 244 : 80)}px;
  background-color: ${({ theme }) => theme.colors.mainColor};
  background-image: url(${({ img }) => img || defaultImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const InfoContainer = styled.section`
  position: relative;
  padding: 16px 20px;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  .link-a {
    color: #1a0dab;
  }
`;

const CategoryName = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
`;

const ProductTitle = styled.h1`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 8px;
`;

const ProductInfo = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  white-space: pre-line;
`;

const PriceContainer = styled.div`
  position: absolute;
  top: 1px;
  left: 0px;
  transform: translate(0%, -100%);
  width: 100%;
  height: 16px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  box-shadow: 0px -16px 16px rgba(0, 0, 0, 0.24);
`;

const PriceTag = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  background: ${({ theme }) => theme.colors.mainColorDark};
  padding: 8px 24px;
  border-radius: 16px;
  position: absolute;
  top: -8px;
  right: 24px;
  transform: translate(0%, -50%);
`;

const TagsContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const BackHomeLink = styled(Link)`
  align-self: center;
  width: fit-content;
  height: 48px;
  padding: 14px 16px;

  box-shadow: none;
  outline: none;
  background: none;
  border: none;

  color: ${({ theme }) => theme.colors.mainColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
`;

const FullScreenImage = styled.div`
  display: ${({ fullScreen }) => (fullScreen ? "block" : "none")};
  position: ${({ fullScreen }) => (fullScreen ? "fixed" : "initial")};
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 100000;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: ${({ fullScreen }) => (fullScreen ? "block" : "none")};
    position: ${({ fullScreen }) => (fullScreen ? "fixed" : "initial")};
    right: 12px;
    top: 12px;
  }
`;

const ImageFullScreen = styled.div`
  height: 80%;
  width: 90%;
  background-image: url(${({ img }) => img || defaultImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export {
  PageWrapper,
  ImageContainer,
  InfoContainer,
  CategoryName,
  ProductTitle,
  ProductInfo,
  PriceContainer,
  PriceTag,
  TagsContainer,
  BackHomeLink,
  FullScreenImage,
  ImageFullScreen,
};
