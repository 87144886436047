import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultImg from '../../assets/img/productDefaultImg.png';

import { Container, ContainerImg } from './styles';
import TranslatorFunction from "../../components/I18n/Translator"


function Carousel({ imgsURL, selectImg, isNullImage }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const productImage = TranslatorFunction({ path: 'productImage.message' })

  return (
    <Container>
      <Slider {...settings}>
        {imgsURL?.map((img) => (
          <ContainerImg
            onClick={() => selectImg(img)}
            isNullImage={isNullImage}
          >
            <img src={img ?? defaultImg} alt={productImage} />
          </ContainerImg>
        ))}
      </Slider>
    </Container>
  );
}

export default Carousel;
