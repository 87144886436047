/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import CurrencyMask from "react-currency-format";

import { Container } from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import TranslatorFunction from "../../../components/I18n/Translator"

function InputMoney(props) {
  const { value, onChange, onBlur } = props;
  const [displayValue, setDisplayValue] = useState("");
  const debouncedChange = useDebounce(onChange, 500);

  const handleOnChange = (event) => {
    setDisplayValue(event.target.value);
    debouncedChange(event.target.value);
  };

  const handleOnClick = (event) => {
    if (event.target.value === "R$ 0,00") {
      setDisplayValue("");
      debouncedChange("");
    }
  };

  const handleOnBlur = (event) => {
    onBlur(event.target.value);
  };

  useEffect(() => {
    setDisplayValue(value);
  }, []);

  const changeFor = TranslatorFunction({ path: 'changeFor.message' });

  return (
    <Container>
      <CurrencyMask
        thousandSeparator={"."}
        prefix={"R$ "}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={true}
        customInput={TextField}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        label={changeFor}
        value={displayValue}
        onClick={handleOnClick}
      />
    </Container>
  );
}

export default InputMoney;
