import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import restMenus from '../../api/menus/rest-menus';
import ProductsList from '../../components/productsList/ProductsList';
import { getOperationId } from '../../services/localStorage';
import { scrollToTop } from '../../utils';
import PageTitle from './ProductsStyles';
import TranslatorFunction from "../../components/I18n/Translator"

const Products = () => {
  const language = TranslatorFunction({ path: 'language.message' });
  const {
    subcategory: subCategoryName,
    domain,
  } = useParams();
  const location = useLocation();
  // eslint-disable-next-line
  const { subCategory } = location?.state;

  const enableIn = getOperationId();
  const [productsList, setProductsList] = useState([]);
  const handleMenusResponse = (response) => {
    const products = response.data.products;
    setProductsList(products);
  };

 sessionStorage.setItem('subCategory', JSON.stringify(subCategory));

  const fecthMenusSubCategory = () => {
    restMenus
      .fecthMenusSubCategory(
        domain,
        subCategory.category._id,
        subCategory._id,
        enableIn
      )
      .then(handleMenusResponse);
  };

  useEffect(() => {
    fecthMenusSubCategory();
    scrollToTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
    
      <PageTitle>{`${decodeURIComponent(
        subCategory.category?.translatedName[0] ? (subCategory.category.translatedName[0].name[0][language] !== undefined ? subCategory.category.translatedName[0].name[0][language] :  subCategory.category.name):  subCategory.category.name
      )}   ➞   ${subCategory?.translatedName[0] ? ( subCategory.translatedName[0].name[0][language] !== undefined? subCategory.translatedName[0].name[0][language] :  subCategory.name):  subCategory.name}`}</PageTitle>
      <ProductsList products={productsList} categoryName={subCategoryName} language={language} />
    </>
  );
};

export default Products;
