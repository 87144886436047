export default {
  translations: {
    language: {
      message: 'it',
    },
    welcomeToOurDigitalMenu: {
      message: 'Benvenuto nel nostro menu digitale',
    },
    beWelcome: {
      message: 'Benvenuto',
    },
    hello: {
      message: 'Ciao',
    },
    comments: {
      message: 'Commenti',
    },
    selectUntil: {
      message: 'seleziona fino a',
    },
    optionsYouSelected: {
      message: ' opzioni. hai selezionato',
    },
    youMustAddAllRequiredComplements: {
      message: 'Devi aggiungere tutti i componenti aggiuntivi richiesti.',
    },
    myOrder: {
      message: 'LA MIA RICHIESTA',
    },
    emptyCart: {
      message: 'CARRELLO VUOTO',
    },
    addMoreItems: {
      message: 'AGGIUNGI ALTRI ARTICOLI',
    },
    subtotal: {
      message: 'TOTALE PARZIALE',
    },
    cartQuantity: {
      message: 'Qty',
    },
    cartItem: {
      message: 'Elemento',
    },
    cartValue: {
      message: 'Valore',
    },
    total: {
      message: 'TOTALE',
    },
    formOfPayment: {
      message: 'FORMA DI PAGAMENTO',
    },
    deliveryFee: {
      message: 'Tassa di consegna',
    },
    deliveryIn: {
      message: 'consegna in',
    },
    sendOrder: {
      message: 'INVIA RICHIESTA',
    },
    ourResponseTimeIsUpTo: {
      message: 'Il nostro tempo di risposta è fino a',
    },
    minutes: {
      message: 'minuti',
    },
    back: {
      message: 'RITORNO',
    },
    backToMenu: {
      message: 'TORNA AL MENU',
    },
    confirmAddress: {
      message: 'CONFERMA INDIRIZZO',
    },
    confirmTakAway: {
      message: 'CONFERMA RECESSO',
    },
    invalidCoupon: {
      message: 'Buono non valido.',
    },
    discountApplied: {
      message: 'Sconto applicato.',
    },
    discount: {
      message: "SCONTO"
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: 'Devi chiedere il resto per un importo maggiore di',
    },
    checkAllFields: {
      message: 'Controlla tutti i campi.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: 'Seleziona un prodotto prima di procedere',
    },
    selectAPaymentMethod: {
      message: 'Seleziona un metodo di pagamento',
    },
    chooseOneOfTheOptions: {
      message: 'Scegli una delle opzioni',
    },
    catalog: {
      message: 'Catalogare',
    },
    deliveryTakeAway: {
      message: 'Consegna | ritiro',
    },
    localConsumption: {
      message: 'Consumo Locale',
    },
    simpleEasyFastDigital: {
      message: 'Semplice. Facile. Veloce. Digitale.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: 'Le immagini sono solo a scopo illustrativo.',
    },
    changeFor: {
      message: 'cambia in',
    },
    productImage: {
      message: 'Immagine del prodotto',
    },
    weAreNotOpenYet: {
      message: 'non siamo ancora aperti',
    },
    checkOur: {
      message: "Dai un'occhiata al nostro",
    },
    openingHours: {
      message: 'orari di apertura',
    },
    accessMenu: {
      message: 'ACCEDI AL MENU',
    },
    oops: {
      message: 'ops',
    },
    browse: {
      message: 'NAVIGARE',
    },
    theHighlights: {
      message: 'DAI PUNTI SALIENTI',
    },
    helloHowAreYou: {
      message: 'Ciao, come stai?',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: 'Per ora, il nostro menù è accessibile solo da cellulare.',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'Stiamo già lavorando per offrire la migliore esperienza di menu online accessibile da qualsiasi dispositivo.',
    },
    identification: {
      message: 'IDENTIFICAZIONE',
    },
    mandatoryFilling: {
      message: 'riempimento obbligatorio',
    },
    name: {
      message: 'Nome',
    },
    phone: {
      message: 'Telefono',
    },
    cityTakeAway: {
      message: 'Città/Ritiro',
    },
    street: {
      message: 'Strada',
    },
    neighborhoods: {
      message: 'quartieri',
    },
    number: {
      message: 'Numero',
    },
    complement: {
      message: 'Complemento',
    },
    reference: {
      message: 'Riferimento',
    },
    coupon: {
      message: 'BUONO',
    },
    add: {
      message: 'INSERISCI',
    },
    typeWhatYouAreLookingFor: {
      message: 'Digita quello che stai cercando...',
    },
    goodThatYouAreHere: {
      message: 'bene che sei qui',
    },
    chooseTheItemYouWantAnd: {
      message: "Scegli l'articolo che desideri e",
    },
    toPlaceYourOrder: {
      message: 'per effettuare il tuo ordine',
    },
    callAnAttendant: {
      message: ' chiamare un addetto',
    },
    SUN: {
      message: 'DOM',
    },
    MON: {
      message: 'LUN',
    },
    TUES: {
      message: 'MAR',
    },
    WED: {
      message: 'MER',
    },
    THU: {
      message: 'GIO',
    },
    FRI: {
      message: 'VEN',
    },
    SAT: {
      message: 'SAB',
    },
    from: {
      message: "IN"
    },
    to: {
      message: "fino al"
    },
    hum: {
      message: 'hum',
    },
    nothave: {
      message: 'sembra che non ce l abbiamo ancora'
    },
    ismenu: {
      message:  'È nel nostro menù...'
    },
    Additional: {
      message: "Aggiuntivo"
    },
    takeAway: {
      message: "Porta via"
    },
  },
};
