import React from "react";
import { PropTypes } from "prop-types";
import ProductsList from "../../../components/productsList/ProductsList";
import {
  SearchResultsWrapper,
  LabelText,
  CategoryLabelContent,
  ProductContent,
  MainColorIcon,
  NotFoundContent,
  NotFoundText,
} from "./SearchResultsStyles";
import SmileyNeutralIcon from "../../../assets/img/smileyNeutralIcon.svg";
import TranslatorFunction from "../../../components/I18n/Translator"

const SearchResults = ({ products, categoryName }) => {
  const categoryText = `EM ${categoryName.toUpperCase()}`;
  const hum = TranslatorFunction({ path: 'hum.message' });
  const nothave = TranslatorFunction({ path: 'nothave.message' });
  const ismenu = TranslatorFunction({ path: 'ismenu.message' });
  const language = TranslatorFunction({ path: 'language.message' });
  const notFoundText = (
    <NotFoundText>
      {hum}
      <br />
      {nothave}
      <br />
      {ismenu}
    </NotFoundText>
  );
  const renderProductList = () => (
    <ProductContent>
      <CategoryLabelContent>
        <LabelText>{categoryText}</LabelText>
      </CategoryLabelContent>
      <ProductsList
        products={products}
        language={language}
        categoryName={categoryName}
        isSearch={true}
      />
    </ProductContent>
  );

  const renderNotFound = () => (
    <NotFoundContent>
      <MainColorIcon className="large" src={SmileyNeutralIcon} />
      {notFoundText}
    </NotFoundContent>
  );

  return (
    <SearchResultsWrapper>
      {products.length > 0 ? renderProductList() : renderNotFound()}
    </SearchResultsWrapper>
  );
};

SearchResults.propTypes = {
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  categoryName: PropTypes.string.isRequired,
};

export default SearchResults;
