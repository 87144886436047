import styled from "styled-components";

export const Container = styled.div`
  * {
    color: #ff5533;
  }
  span {
    color: #858585 !important;
  }
  .MuiAccordionDetails-root {
    padding: 8px 32px 16px;
  }

  .money {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 18px;
  }

  .helpfull {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10px;
  }
`;
