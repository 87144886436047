import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 40px auto;
`;

export const TitleWrapper = styled.div`
  h1 {
    font-size: 32px;
    color: #ff5533;
    font-weight: normal;
    text-align: center;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 24px;
    color: #4b4b4b;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const ImageWrapper = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
`;

export const SubtitleWrapper = styled.div`
  h3 {
    color: #565656;
    font-size: 16px;
    font-weight: lighter;
    text-align: center;
    line-height: 24px;
    b {
      font-weight: normal;
      color: #565656;
    }
  }
`;

export const HoursWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 60%;
  margin: 20px auto;
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    margin-left: 16%;
  }
  .hours {
    flex-direction: column;
    h6 {
      font-size: 16px;
      font-weight: normal;
      color: #858585;
    }
  }
  div {
    display: flex;
    align-items: center;
    h1 {
      color: #ff5533;
      font-size: 16px;
      font-weight: lighter;
      margin-right: 24px;
    }
    margin-bottom: 2px;
  }

  button {
    margin-top: 36px;
    a {
      color: #fff;
    }
  }
  a {
    margin-top: 36px;
  }
`;
