export const clearSession = () => sessionStorage.clear();

export const setModeOperation = (value) =>
  sessionStorage.setItem("delivery", value);

export const setOperationId = (value) =>
  sessionStorage.setItem("enableId", value);

export const getModeOperation = () => sessionStorage.getItem("delivery");

export const getOperationId = () => sessionStorage.getItem("enableId");

export const getCartStorage = () => JSON.parse(sessionStorage.getItem("cart"));

export const setCartStorage = (value) => sessionStorage.setItem("cart", JSON.stringify(value));

export const removeCart = () => sessionStorage.removeItem("cart");

export const setFantasyName = (value) =>
  sessionStorage.setItem("fantasyName", value);

export const getFantasyName = () => sessionStorage.getItem("fantasyName");

export const getTakeaway = () => sessionStorage.getItem("TakeAway");

export const setTakeaway = (value) => sessionStorage.setItem("TakeAway", value);
