export default {
  translations: {
    language: {
      message: 'pt',
    },
    welcomeToOurDigitalMenu: {
      message: 'Bem vindo ao nosso menu digital',
    },
    beWelcome: {
      message: 'Bem-vindo',
    },
    hello: {
      message: 'Olá',
    },
    comments: {
      message: 'Observações',
    },
    selectUntil: {
      message: 'Selecione até',
    },
    optionsYouSelected: {
      message: ' opções. Você selecionou',
    },
    youMustAddAllRequiredComplements: {
      message: 'Você deve adicionar todos os complementos obrigatorios.',
    },
    myOrder: {
      message: 'MEU PEDIDO',
    },
    emptyCart: {
      message: 'ESVAZIAR CARRINHO',
    },
    addMoreItems: {
      message: 'ADICIONAR MAIS ITENS',
    },
    subtotal: {
      message: 'SUBTOTAL',
    },
    total: {
      message: 'TOTAL',
    },
    formOfPayment: {
      message: 'FORMA DE PAGAMENTO',
    },
    deliveryFee: {
      message: 'Taxa de Entrega',
    },
    deliveryIn: {
      message: 'Entrega em',
    },
    sendOrder: {
      message: 'ENVIAR PEDIDO',
    },
    ourResponseTimeIsUpTo: {
      message: 'Nosso tempo de resposta é de até ',
    },
    minutes: {
      message: 'minutos',
    },
    back: {
      message: 'VOLTAR',
    },
    backToMenu: {
      message: 'VOLTAR PARA O MENU',
    },
    confirmAddress: {
      message: 'CONFIRMAR ENDEREÇO',
    },
    confirmTakAway: {
      message: 'CONFIRMAR RETIRADA',
    },
    invalidCoupon: {
      message: 'Cupom inválido.',
    },
    discountApplied: {
      message: 'Desconto aplicado.',
    },
    discount: {
      message: 'DESCONTO'
    },
    youMustAskForChangeForAnAmountGreaterThan: {
      message: 'Você deve pedir troco para um valor maior que',
    },
    checkAllFields: {
      message: 'Verifique todos os campos.',
    },
    pleaseSelectAProductBeforeProceeding: {
      message: 'Selecione um produto antes de continuar',
    },
    selectAPaymentMethod: {
      message: 'Selecione um metódo de pagamento',
    },
    chooseOneOfTheOptions: {
      message: 'Escolha uma das opções',
    },
    catalog: {
      message: 'Catálogo',
    },
    deliveryTakeAway: {
      message: 'Delivery | Retirada',
    },
    localConsumption: {
      message: 'Consumo Local',
    },
    simpleEasyFastDigital: {
      message: 'Simples. Fácil. Rápido. Digital.',
    },
    imagesAreForIllustrationPurposesOnly: {
      message: 'As imagens são meramente ilustrativas.',
    },
    changeFor: {
      message: 'Troco para',
    },
    productImage: {
      message: 'Imagem do Produto',
    },
    weAreNotOpenYet: {
      message: 'Ainda não estamos abertos',
    },
    checkOur: {
      message: 'Confira nosso',
    },
    openingHours: {
      message: 'horário de atendimento',
    },
    accessMenu: {
      message: 'ACESSAR MENU',
    },
    oops: {
      message: 'Ops',
    },
    browse: {
      message: 'NAVEGUE',
    },
    theHighlights: {
      message: 'PELOS DESTAQUES',
    },
    helloHowAreYou: {
      message: 'Olá, tudo bem?',
    },
    cartQuantity: {
      message: 'Qtd.',
    },
    cartItem: {
      message: 'Item',
    },
    cartValue: {
      message: 'Valor',
    },
    forNowOurMenuCanOnlyBeAccessedOnMobilePhones: {
      message: 'Por enquanto, nosso menu só pode ser acessado nos celulares.',
    },
    weAreAlreadyWorkingToBringYouTheBestOnlineMenuExperienceAccessibleFromAnyDevice: {
      message: 'Já estamos trabalhando para trazer a melhor experiência em cardápio online acessada de qualquer dispositivo.',
    },
    identification: {
      message: 'IDENTIFICAÇÃO',
    },
    mandatoryFilling: {
      message: 'preenchimento obrigatório',
    },
    name: {
      message: 'Nome',
    },
    phone: {
      message: 'Telefone',
    },
    cityTakeAway: {
      message: 'Cidade/Retirada',
    },
    street: {
      message: 'Rua',
    },
    neighborhoods: {
      message: 'Bairros',
    },
    number: {
      message: 'Número',
    },
    complement: {
      message: 'Complemento',
    },
    reference: {
      message: 'Referência',
    },
    coupon: {
      message: 'CUPOM',
    },
    add: {
      message: 'ADICIONAR',
    },
    typeWhatYouAreLookingFor: {
      message: 'Digite o que você procura...',
    },
    goodThatYouAreHere: {
      message: 'Que bom que você está aqui',
    },
    chooseTheItemYouWantAnd: {
      message: 'Escolha o item que desejar e',
    },
    toPlaceYourOrder: {
      message: 'para realizar o seu pedido',
    },
    callAnAttendant: {
      message: ' chame um atendente ',
    },
    SUN: {
      message: 'DOM',
    },
    MON: {
      message: 'SEG',
    },
    TUES: {
      message: 'TER',
    },
    WED: {
      message: 'QUA',
    },
    THU: {
      message: 'QUI',
    },
    FRI: {
      message: 'SEX',
    },
    SAT: {
      message: 'SÁB',
    },
    from: {
      message: "DE"
    },
    to: {
      message: "até"
    },
    hum: {
      message: 'Hmmm',
    },
    nothave: {
      message: "parece que ainda não temos"
    },
    ismenu: {
      message: "isso em nosso cardápio..."
    },
    Additional: {
      message: "Adicional"
    },
    takeAway: {
      message: "Retirada no Local"
    },
  },
};
