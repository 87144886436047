import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkStyled = styled(Link)`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  align-self: center;
  width: fit-content;
  height: 48px;
  padding: 14px 16px;

  box-shadow: none;
  outline: none;
  background: none;
  border: none;

  color: ${({ theme }) => theme.colors.mainColor};
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
`;

export default LinkStyled;
