import styled from 'styled-components';

const PageTitle = styled.h1`
  margin: 24px 0px 24px 28px;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
`;

export default PageTitle;
