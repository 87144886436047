import styled, { css } from "styled-components";

const cssPrimary = css`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorLight : theme.colors.textColorContrast};
`;

const cssSecondary = css`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.mainColorContrast : theme.colors.mainColorContrast};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
`;

const cssTertiary = css`
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColor};
  border: none;
`;

export const LinkStyled = styled.a`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 100%;
  height: 54px;
  text-align: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  ${({ type }) =>
    (type === "primary" && cssPrimary) ||
    (type === "secondary" && cssSecondary) ||
    (type === "tertiary" && cssTertiary)};
`;

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  margin: 36px 0 10px;
  h1 {
    font-weight: lighter;
    color: #858585;
    text-align: center;
    margin-bottom: 12px;
  }
`;

export const ContainerButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 0 auto;

  h2:first-child {
    border-right: 2px solid #ffdcd5;
  }

  h1,
  h2 {
    font-size: 15px;
    color: #ff5533;
    text-align: center;
    margin-top: 12px;
  }
  border-bottom: 2px solid #ffdcd5;
  padding-bottom: 24px;
`;

export const ContainerSubtotal = styled.div`
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ffdcd5;
  padding-bottom: 24px;
  h1 {
    font-size: 20px;
    margin: 0 16px;
  }
`;

export const ContainerPayment = styled.div`
  padding-bottom: 12px;
  h1 {
    color: #858585;
    font-weight: lighter;
    text-align: center;
    margin: 24px 0;
  }
`;

export const ResponseTime = styled.div`
  margin: 20px 0px 0px 0px;
  text-align: center;
  color: #565656;
`;

export const ContainerButtonsFooter = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    font-size: 17px;
    font-weight: 600;
    a {
      color: white;
    }
  }
  a {
    margin-top: 12px;
    text-align: center;
  }
`;

export const DeliveryIn = styled.div`
  width: 90%;
  margin: 6px auto;
  h3 {
    color: #565656;
    text-align: center;
    font-size: 16px;
  }
  h4 {
    color: #959595;
    font-size: 14px;
    text-align: center;
  }
`;

export const ContainerResults = styled.div`
  /* border-top: 2px solid #ffdcd5; */
  border-bottom: 2px solid #ffdcd5;
  padding: 8px 0 21px;
  .total {
    margin-top: 12px;
    div {
      display: flex;
      width: 90%;
      margin: 0 auto;
      justify-content: space-between;
      text-align: center;
      h1 {
        color: #565656;
        font-size: 16px;
        h4 {
          text-align: left;
          color: #959595;
        }
      }
    }
  }
  .totalnumber {
    margin-top: 36px !important;
    h1 {
      color: black !important;
      font-size: 26px !important;
    }
  }
`;
