import styled, { keyframes } from 'styled-components';
import { ReactSVG } from 'react-svg';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const SearchResultsWrapper = styled.div`
  height: 100%;
  -webkit-overflow-scrolling: touch;
`;

const CategoryLabelContent = styled.div`
  margin-bottom: 16px;
`;

const ProductContent = styled.div`
  min-height: 100px;
  white-space: pre-wrap;
`;

const NotFoundContent = styled.div`
  text-align: center;
  height: calc(50vh);
  min-height: 290px;                                                                                                                                                                                                                                                                                                                                                                                                    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotFoundText = styled.p`
  margin-top: 14px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.textColor};
  animation-name: ${fadeIn};
  animation-duration: 0.5s;
`;

const LabelText = styled.label`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  margin-left: 50px;
  color: ${({ theme }) => theme.colors.textColor};
`;

const MainColorIcon = styled(ReactSVG)`
  animation-name: ${fadeIn};
  animation-duration: 0.5s;

  svg path {
    fill:  ${({ theme }) => theme.colors.mainColorLight};
  }

  &.large {
    div svg {
      width: 66px;
      height: 66px;
    }
  }

  &.medium {
    div svg {
      width: 32px;
      height: 32px;
    }
    
  }

  &.small {
    div svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export {
  SearchResultsWrapper,
  CategoryLabelContent,
  ProductContent,
  NotFoundContent,
  NotFoundText,
  MainColorIcon,
  LabelText,
};
