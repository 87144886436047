import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Container,
  TitleWrapper,
  SubtitleWrapper,
  ImageWrapper,
  HoursWrapper,
} from './styles';
import ClockSVG from '../../assets/img/clock.svg';
import { converter } from '../../utils';
import Button from '../button/Button';
import Link from '../link/Link';
import TranslatorFunction from '../I18n/Translator'

function dayTranslated(day){
    let language = localStorage.getItem('i18nextLng')
    switch (day) {
      case 'DOM':
        return TranslatorFunction({ path: "SUN.message" });
      case 'SEG':
        return TranslatorFunction({ path: "MON.message" });
      case 'TER':
        return TranslatorFunction({ path: "TUES.message" });
      case 'QUA':
      case 'QUAR':
        return TranslatorFunction({ path: "WED.message" });
      case 'QUI':
        return TranslatorFunction({ path: "THU.message" });
      case 'SEX':
        return TranslatorFunction({ path: "FRI.message" });
      case 'SÁB':
        return TranslatorFunction({ path: "SAT.message" });
      default:
        return "ERrOO";
    }
  
}

function Closed({ hours }) {
  const { domain } = useParams();

  const weAreNotOpenYet = TranslatorFunction({ path: 'weAreNotOpenYet.message' });
  const checkOur = TranslatorFunction({ path: 'checkOur.message' });
  const openingHours = TranslatorFunction({ path: 'openingHours.message' });
  const accessMenu = TranslatorFunction({ path: 'accessMenu.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const oops = TranslatorFunction({ path: 'oops.message' });
  const to = TranslatorFunction({ path: 'to.message' });

  return (
    <Container>
      <TitleWrapper>
        <h1>
          {oops}
          !
        </h1>
        <h2>
          {weAreNotOpenYet}
        </h2>
      </TitleWrapper>
      <ImageWrapper>
        <img src={ClockSVG} alt="" srcset="" />
      </ImageWrapper>
      <SubtitleWrapper>
        <h3>
          {checkOur}
          <br />
          <b>
            {openingHours}
            :
          </b>
        </h3>
      </SubtitleWrapper>
      <HoursWrapper>
        {hours?.map((hour) => (
          <div className="wrapper">
            <h1>{dayTranslated(hour?.day)}</h1>
            <div className="hours">
              <h6>
                {converter(hour?.opened1)} {to} {converter(hour?.closed1)}
              </h6>
              <h6>
                {hour?.closed2 !== 0 &&
                  hour?.opened2 !== 0 &&
                  `${converter(hour?.opened2)}  ${to}   ${converter(hour?.closed2)}`}
              </h6>
            </div>
          </div>
        ))}

        <Button>
          <Link to={`/${domain}/local/menu`}>
            {accessMenu}
          </Link>
        </Button>

        <Link to={`/${domain}`}>
          {back}
        </Link>
      </HoursWrapper>
    </Container>
  );
}

export default Closed;
